@import url("https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro:200,400");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  /* background-color: rgb(209, 151, 84); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

/* a {
  background-color: #3ab6bf;
  text-decoration: none;
  color: white;
  border-radius: 0.25rem;
  display: inline-block;
  padding: 0.1em 0.4em;
  margin-left: 0.5em;
} */

main.wrapper {
  max-width: 1300px;
  margin: auto;
}

/* head-contentER */
.head-content {
  background: linear-gradient(#3ab6bf5e, #1a5271c7),
    url("https://www.mnnonline.org/wp-content/uploads/2014/04/BJP-flag.svg_.jpg")
      no-repeat top right;
  background-size: cover;
  color: #fff;
  padding: 1rem;
  object-fit: contain;
}

.head-content h1 {
  font-size: 3rem;
  text-shadow: 2px 2px 5px #1a5271;
  margin-right: 30px;
}

.head-content p {
  font-size: 1.25rem;
  font-weight: 200;
  text-shadow: 2px 2px 5px #1a5271;
}

.head-content a {
  font-size: 1rem;
}

/* work-content */
.work-content {
  padding: 2rem;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1rem;
}

.work-content article {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.work-content figure {
  display: grid;
  grid-template-rows: 350px 50px;
}

.work-content figure img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  object-fit: cover;
  width: 100%;
  height: 350px;
}

.work-content figcaption {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.work-content figcaption > h3 {
  color: white;
  padding: 0.75rem;
  font-size: 1.25rem;
}

.work-content p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 0.75rem;
  color: #666666;
}

/* MEDIA QUERY */
@media screen and (min-width: 800px) {
  .head-content {
    background-position: center center;
    padding: 4em 2em;
    display: flex;
    align-items: center;
  }

  .head-content p {
    flex: 1;
  }

  .work-content {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}
