.slider {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: white;
}
/* .image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
} */
.image h1 {
  display: flex;
  flex-wrap: wrap;
  width: 75vw;
  margin: 10px;
}
/* '\201c' '\201d'; */
.image::before {
  content: "\201C";
  font-size: 50px;
  color: rgb(60, 101, 163);
  font-weight: bold;
}

.image::after {
  content: "\201D";
  font-size: 50px;
  color: rgb(60, 101, 163);
  font-weight: bold;
}
h1#Overflow.image {
  overflow-wrap: break-word;
  hyphens: auto;
  width: 75%;
  z-index: 0;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: black;

  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: black;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s;
}
.slide-active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
