.NavbarItems {
  background: linear-gradient(
    90deg,
    rgb(175, 136, 99) 0%,
    rgb(252, 69, 63) 100%
  );
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 1000;
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 10px;
  cursor: pointer;
}
.fa-react {
  margin-left: 0.5rem;
  font-size: 2rem;
}
.bjpImage {
  height: 60px;
  width: 100px;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  z-index: 100;
}
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links:hover {
  background-color: #6d76f7;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.fa-bars {
  color: #fff;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
.POSITION {
  position: sticky;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 500px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .nav-menu.active {
    background: #6d76f7;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    height: 25vh;
    /* z-index: 1; */
  }
  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #6d76f7;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .bjpImage {
    height: 60px;
    width: 100px;
    position: absolute;
    left: 8px;
    top: 20px;
  }
  .POSITION {
    position: sticky;
    top: 0;
    width: 100%;
  }
}
