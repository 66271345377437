:root {
  --primary: #2d6607;
}
.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  background: #6568f4;
  transition: 250ms;
}
.btn--primary {
  background-color: var(--primary);
}
.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: 00.3s ease ease-out;
}
.btn--medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 18px;
  color: #fff;
}
.btn--large {
  padding: 12px 26px;
  border-radius: 4px;
  font-size: 20px;
  color: #fff;
}
.Progress_link {
  list-style: none;
  text-align: center;
  text-decoration: none;
  color: white;
}
