.page {
  display: grid;
  height: 100px;
  background-color: #00838f;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
}
.flex-center i {
  color: white;
  padding-left: 50px;
  font-size: 30px;
}
.page {
  display: grid;
  height: 150px;
  background-color: #00838f;
  justify-content: center;
  align-items: center;
  color: white;
}
.footer-copyright {
  height: 50px;
  background-color: #00838f;
  text-align: center;
  color: white;
}
.fab {
  margin-top: 10px;
}
