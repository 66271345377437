/* .sliderHome {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgb(209, 114, 24) 0%,
    rgb(252, 69, 63) 100%
  );
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: white;
} */
/* .image {
    width: 1000px;
    height: 600px;
    border-radius: 10px;
  } */
.imageHome h1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
}

#OverflowHome.image > h1 {
  overflow-wrap: break-word;
  hyphens: auto;
  width: 300vw;
}

.slideHome {
  opacity: 0;
  transition-duration: 1s;
  width: 100vw;
  height: auto;
}
.slidehome-active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  height: auto;
}
/* .responsive {
  max-width: 100%;
  height: auto;
}
#overflowHome {
  max-width: 100%;
  height: auto;
} */
