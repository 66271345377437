.App {
  display: flex;
  flex-direction: column;
}
* {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
img {
  height: auto;
  width: 100%;
}
.slogan {
  position: absolute;
  left: 20px;
  font-size: 40px;
  color: rgb(0, 225, 255);
}
