/*Background Image*/
video {
  object-fit: cover;
  width: 100%;
  height: auto;
  position: fixed;
  z-index: -5;
}
.hero-container {
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
}
.hero-container img {
  height: 88vh;
  width: 100vw;
}
#Heading_Container {
  position: absolute;
  width: 250px;
  top: 350px;
  left: 50px;
  color: rgba(10, 47, 82, 0.925);
  margin: 10px;
  z-index: 1;
  display: flex;
}

/*Fb PAGE*/
.fb-page {
  margin: 10px;
  z-index: 2;
  width: 50%;
}

/*quote-Home*/
.quote-Home {
  background: linear-gradient(
    90deg,
    rgb(209, 114, 24) 0%,
    rgb(252, 69, 63) 100%
  );
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  margin-top: 0;
}

@media screen and (max-width: 960px) {
  .hero-container h1 {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 600px;
    color: aliceblue;
    margin: 10px;
    display: flex;
    text-align: center;
  }
  .hero-container {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .hero-container img {
    height: 50vh;
    width: 100vw;
  }
}
@media screen and (max-width: 550px) {
  #Heading_Container {
    position: absolute;
    top: 350px;
    left: 150px;
    font-size: medium;
    top: 150;
    right: 25px;
  }
}
@media screen and (max-width: 500px) {
  #Heading_Container {
    position: absolute;
    top: 450px;
    left: 70px;
    font-size: medium;
    color: white;
    margin: 10px;
    z-index: 1;
    display: flex;
  }
  .hero-container {
    height: 50vh;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .hero-container img {
    height: 50vh;
    width: 100vw;
  }
}

@media screen and (max-width: 400px) {
  #Heading_Container {
    position: absolute;
    top: 400px;
    left: 70px;
    font-size: medium;
    color: white;
    margin: 10px;
    z-index: 1;
    display: flex;
  }
}
@media screen and (max-width: 376px) {
  #Heading_Container {
    position: absolute;
    top: 300px;
    left: 50px;
    text-align: center;
    top: 150;

    color: white;
    margin: 10px;
    z-index: 1;
    text-align: center;
    font-size: medium;
    align-content: center;
    justify-content: center;
  }
}
@media screen and (max-width: 365px) {
  #Heading_Container {
    position: absolute;
    top: 350px;
    left: 50px;
    font-size: medium;
    top: 150;
    right: 25px;
  }
}
/* 
@media screen and (max-width: 550px) {
  #Heading_Container {
    position: absolute;
    top: 350px;
    left: 100px;
    font-size: medium;
    top: 150;
    right: 25px;
  }
} */
